import {AxiosInstance,AxiosInstancePatch} from "./axiosInstance";

export class MainService {
    searchProduct = (barCode) => AxiosInstance.get('/product/'+barCode);
    addGrid = (Grid) => AxiosInstance.post('/grids', Grid);
    filterGrid = (Grid) => AxiosInstance.get('/grids?registrationNumber='+Grid.registrationNumber+'&drmNumber='+Grid.drmNumber);
    getManufacturers= () => AxiosInstance.get('/manufacturers?productItems.status=ready&exists%5BproductItems%5D=true');
    addItemsToGrid = (GridID, items) => AxiosInstance.post('/product_items/add_to_grid/'+GridID, items);
    addItemToGrid = (GridID, item) => AxiosInstance.post('/product_items/add_to_grid/'+GridID, item);
     finishGrid = (GridID) => AxiosInstancePatch.patch('/product_items/terminate_grid/'+GridID, {});

     getItemsByManufacturer= (ManufacturerId) => AxiosInstance.get('/product_items?status=ready&manufacturer='+ManufacturerId);

    sendItems = (ManufacturerId, items) => AxiosInstance.post('/product_items//send_to_manufacturer/'+ManufacturerId, items);

}
