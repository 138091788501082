import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";
import {
    Landing,
    GridProcess,
    Shipping
} from "./_pages";
import {RequiredAuth} from "./secureRoute";

export default class AppRoutes extends Component {
    render = () => <Routes>
        <Route exact={true}  path='' element={<RequiredAuth />}>
          <Route path="" element={<Landing/>}/>
        </Route>
        <Route exact={true}  path='/traiter-grille' element={<RequiredAuth />}>
          <Route path="" element={<GridProcess/>}/>
        </Route>
        <Route exact={true}  path='/expedier' element={<RequiredAuth />}>
          <Route path="" element={<Shipping/>}/>
        </Route>

    </Routes>

}
