import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import IconNumber from '../_assets/img/numbers.png';
import { useForm } from "react-hook-form"
import {Scanner, ItemScanned, BarcodeScanner} from "../_components";
import {MainService} from "../_services";

function GridProcess()  {
    const navigate = useNavigate();
    const ms = new MainService();
    const [showScanner, setShowScanner] = useState(false);
    const [itemPlaced, setItemPlaced] = useState(false);
    const [validationModal, setValidationModal] = useState(false);
    const [finishModal, setFinishModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [enableTerminate, setenableTerminate] = useState(false);
    const [scannedItem, setScannedItem] = useState([]);
    const [currentGrid, setCurrentGrid] = useState(null);
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm()
    const onSubmit = (data) => {
        console.log(data);
      checkIfGridExist(data)
    }
    const addScannedItem = (item) => {
        setScannedItem(item);
        setShowScanner(false);
        setItemPlaced(true);
    }
    const placeItem = () => {
      let itemScanned = { productId : scannedItem.product.id, manufacturerId : scannedItem.manufacturer.id, serialNumber : scannedItem.serialNumber }
      ms.addItemToGrid(currentGrid.id,itemScanned).then((data)=> {
        setShowScanner(true);
        setItemPlaced(false);
        setenableTerminate(true)
      })
    }
    const finishGrid = () => {
      setLoading(true);
      ms.finishGrid(currentGrid.id).then((data)=> {
        setValidationModal(false);
        setFinishModal(true);
        setLoading(false);
      })
    }
    const checkIfGridExist = (Grid) =>{
      ms.filterGrid(Grid).then((data) => {
        if(data.data && data.data.length > 0){
          setCurrentGrid(data.data[0]);
          setShowScanner(true);
        }
        else{
          createGrid(Grid)
        }

      }).catch((error) => {
        console.log(error);
      })
    }
    const createGrid = (Grid) => {
      ms.addGrid(Grid).then((data)=> {
        setCurrentGrid(data.data);
        setShowScanner(true);
      })
    }
    const goDashboard = () => navigate('/');

    return (
        <div className={"page grid-process"}>
            <h2 className={"bleu "}>
                <Link to="/">
                    <span className={"fa fa-chevron-left back-btn"}></span>
                </Link>
                Traiter une grille 🛒
            </h2>

            {
                (!showScanner && !itemPlaced) &&
                <>
                    <p className={"grey"}>
                        Vous souhaitez créer une nouvelle grille, veuillez remplir les champs ci-dessous.
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)} className={"grid-form"}>
                        <div className={"input-1"}>
                            <img src={IconNumber} className={"icon-i"} alt=""/>
                            <input type="text"
                                   placeholder={"Immatriculation de la grille (obligatoire)"}
                                   {...register("registrationNumber", {required: true})}
                                   aria-invalid={errors.registrationNumber ? "true" : "false"}
                            />
                        </div>
                        {errors.registrationNumber?.type === "required" && (
                            <p className="red">Ce champ est obligatoire</p>
                        )}
                        <div className={"input-1"}>
                            <span className={"fa fa-file icon-i"}></span>
                            <input type="text"
                                   pattern="[0-9]{10}"
                                   maxLength="10"
                                   placeholder={"N° DRM (obligatoire)"}
                                   {...register("drmNumber", {required: true , maxLength : {value : 10, message : 'Le N° DRM doit contenir 10 caractères'}})}
                                   aria-invalid={errors.drmNumber ? "true" : "false"}
                            />
                        </div>
                        {errors.drmNumber?.type === "required" && (
                            <p className="red">Ce champ est obligatoire</p>
                        )}
                        <div className="btns text-center">
                            <button type={'submit'} className={'btn btn-full-blue'}>Commencer la lecture</button>
                        </div>
                    </form>

                </>
            }
            {
                (showScanner && !itemPlaced) &&
                <>
                    <p className={"grey"}>
                        Scannez le numéro de série pour traiter votre “objet”
                    </p>
                    <div className="text-center">
                        <Scanner onScan={(item)=> {
                            addScannedItem(item)
                        }}/>
                    </div>
                    <div className="btns text-center btndown">
                        <button type={'button'} className={'btn btn-full-blue'}
                                disabled={!enableTerminate}
                                onClick={()=> {setValidationModal(true)}}
                        >Terminer la grille</button>
                    </div>
                </>
            }
            {
                (!showScanner && itemPlaced) &&
                <>
                   <ItemScanned
                     ignore={()=>{setShowScanner(true);setItemPlaced(null)}}
                     scannedItem={scannedItem}
                     itemPlaced={placeItem}/>
                </>

            }
            {
                validationModal &&
                <div className={"modal-d"}>
                    <div className="wrapper-modal">
                        <div className={"modal-content small-modal b-btns no-p"}>
                            <div className="content-modal">
                                <h3>
                                  <b>Êtes-vous sûr de vouloir terminer la grille <span className={"bleu"}>
                               {currentGrid && currentGrid.registrationNumber}
                                </span>

                                  </b>
                                </h3>
                            </div>
                          <div className="footer-modal">
                          <button className={"btn btn-white"} type={"button"} onClick={()=> {setValidationModal(false)}}>Annuler</button>
                                <button className={"btn btn-blue"} type={"button"} disabled={loading} onClick={finishGrid}>Terminer</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
          {
            finishModal &&
            <div className={"modal-d"}>
              <div className="wrapper-modal">
                <div className={"modal-content small-modal"}>
                    <h3>
                      <b>Les produits sont bien ajoutés à la grille <span className={"bleu"}>
                               {currentGrid && currentGrid.registrationNumber}
                                </span>

                      </b>
                    </h3>
                  <button className={"btn btn-full-blue"} type={"button"} onClick={goDashboard}>Terminer</button>
                </div>
              </div>
            </div>
          }

        </div>
    )
}

export {GridProcess};
