import './App.scss';
import {useNavigate} from "react-router-dom";
import AppRoutes from './routes';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import {config} from "./config";
const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth,  originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <div className={"wrapper"}>
          <div className={"container"}>
              <AppRoutes/>
          </div>
        </div>
      </div>
    </Security>
  );
}

export default App;
