import Axios from 'axios';
const idT = window.localStorage.getItem('urdg');

const AxiosInstance = Axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
         'X-AUTH-TOKEN': `Bearer ${window.localStorage.getItem('urdg')}`
    },
});
AxiosInstance.interceptors.response.use(
  response => response,
  error => {
    console.log(error)
    // && error.message.toLowerCase().indexOf('expired') !== -1
    if (error.message && (error.message.toLowerCase().indexOf('expired') !== -1  || (error.response && error.response.data.message.toLowerCase().indexOf('wrong number') !== -1)  )) {
      window.location.reload()
    }
    return error;
  });

 const AxiosInstancePatch = Axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/merge-patch+json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
         'X-AUTH-TOKEN': `Bearer ${idT}`
    },
});

export {AxiosInstance , AxiosInstancePatch}
