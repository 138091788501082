import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {SelectProducts} from "../_components";
import {MainService} from "../_services";

function Shipping()  {
    const mainService = new MainService();
    const [finishSelect, setFinishSelect] = useState(false);
    const [manufacturer, setManufacturer] = useState(null);


    const [Manufacturers, setManufacturers] = useState([])

    const onChangeValue = (event, index) =>{
        setManufacturer(Manufacturers[index]);
    }
    const getManufacturers = () => {
      mainService.getManufacturers().then((data)=> {
        setManufacturers(data.data)
      })
    }
  useEffect(()=>{
    getManufacturers()
  }, []);

    return (
        <div className={"page grid-process"}>
            <h2 className={"bleu "}>
                <Link to="/">
                    <span className={"fa fa-chevron-left back-btn"}></span>
                </Link>
                Expédier à un fabriquant ✉️
            </h2>
            {
               !finishSelect &&
                <>
                    <p className={"grey"}>
                        Vous souhaitez expédier les caisses cartons à un fabriquant, veuillez sélectionner un
                        fabriquant.
                    </p>
                    <ul className={"list-1"}>
                        {Manufacturers?.map((data, index) => (
                            <li key={`cb-${index}`}>
                                <input
                                    key={`i-${index}`}
                                    id={`cb-${index}`}
                                    value={data}
                                    name={"manufacturer"}
                                    type="radio"
                                    onChange={(event) => onChangeValue(event,index)}
                                />
                                <label key={`l-${index}`} htmlFor={`cb-${index}`}>{data.name}</label>
                            </li>
                        ))}
                    </ul>
                    <div className="btns text-center">
                        <button type={'button'} className={'btn btn-full-blue'} disabled={!manufacturer} onClick={()=> {setFinishSelect(true)}}>Séléctionner</button>
                    </div>
                </>
            }
            {
                finishSelect &&
                <>
                    <SelectProducts manufacturer={manufacturer} />
                </>
            }


        </div>
    )
}

export {Shipping};
