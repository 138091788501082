import React, {useState} from 'react';
import {MainService} from "../_services";
import {useScanDetection} from "../_services";

function Scanner(props)  {
  const [data, setData] = useState([]);
  const [notFound, setNotFound] = useState([]);
  const ms = new MainService();
  const [value, setValue] = useState('');
  useScanDetection({
    onComplete: (code) => {
      console.log(code);
      setValue(code);
      addItem(code)
    },
  });
    const addItem = (item) => {
        console.log(item)
         searchProduct(item)
    }
    const searchProduct = (barCode) => {
      ms.searchProduct(barCode).then((data)=> {
        setNotFound(false);
        props.onScan({...data.data,serialNumber : barCode})
      }).catch((err) => {
        setNotFound(true);
        alert('Article non reconnu !')
      })
    }
    return (
      <>
        <p className={"mt_15"}>Scanner ou saisir le numéro de série : </p>
        <div className={"input-1"}>
          <span className={"fa fa-file icon-i"}></span>
          <input type="text"
                 placeholder={"N° de série "}
                 value={value}
                 onChange={e => setValue(e.target.value)}
          />
          <button disabled={!value} onClick={()=> {addItem(value)}} className={"btn-send"}><i className={"fa fa-paper-plane"}></i></button>
        </div>
      </>
    )
}

export {Scanner};
