import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import Loading from './_components/loading'
export const RequiredAuth = () => {
  
  const { oktaAuth, authState } = useOktaAuth();
  const [isAuth, setIsAuth] = useState(false)

  useEffect(() => {
    login()
  }, []);
  const login = () => {
    if(window.location.href.indexOf('code=') > 0){
      oktaAuth.token.parseFromUrl().then(
        (tokens) => {
          window.localStorage.setItem('urdg', tokens.tokens.idToken.idToken);
          setIsAuth(true)
        }
      );

    }
    else{
      oktaAuth.isAuthenticated().then((isAuthenticated)=>{
        console.log(isAuthenticated)
        if(!isAuthenticated){
          oktaAuth.signInWithRedirect();
        }
        else{
          oktaAuth.tokenManager.get('id_token').then((token)=> {
            window.localStorage.setItem('urdg', token);
            setIsAuth(true)
          })
        }
      })
    }
  }
  if (!isAuth) {
    return (<Loading />);
  }



  return (<Outlet />);
}
