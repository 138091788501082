import React, {useState} from 'react';

function ItemScanned(props)  {
    const [loading, setLoading] = useState(false);
    const placeInBox = () => {
        props.itemPlaced();
        setLoading(true)
    }
    const ignore = () => {
      props.ignore()
    }

    return (
        <div className={"page grid-process"}>
            <div className={"input-1"}>
                <span className={"fa fa-file icon-i"}></span>
                <span>
                  {
                    props.scannedItem &&
                    props.scannedItem.product.name
                  }
                </span>
            </div>
            <div className={"input-1"}>
                <span className={"fa fa-file icon-i"}></span>
                <span>
                    {
                      props.scannedItem &&
                      props.scannedItem.manufacturer.name
                    }
                </span>
            </div>
            <div className={"input-1"}>
                <span className={"fa fa-file icon-i"}></span>
                <span>
                  {
                  props.scannedItem &&
                  props.scannedItem.product.sku
                }
                </span>
            </div>

          <div className="btns text-center">
            <button type={'button'} disabled={loading} className={'btn btn-full-blue'} onClick={placeInBox}>Poser dans le carton</button>
            <button type={'button'} className={'btn btn-full-grey mt_15'} onClick={ignore}>Ignorer</button>
          </div>
        </div>
    )
}

export {ItemScanned};
