import React, {useState, useEffect} from 'react';
import Logo from '../_assets/img/logo.svg';
import Hand from '../_assets/img/hand.png';
import { Link } from 'react-router-dom';
import {userService} from "../_services";
import { useOktaAuth } from '@okta/okta-react';

function Landing()  {
    const UserService = new userService();
    const [user,setUser] = useState(null);
    const { authState, oktaAuth } = useOktaAuth();

    const getUserConnected = (idToken) => {
        UserService.getUserConnected().then((user)=> {
            setUser(user.data)
        })
    }
    useEffect(()=>{
      getUserConnected();
    }, []);

    useEffect(() => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUser(null);
      } else {
        window.localStorage.setItem('urdg',authState.idToken.idToken);
        getUserConnected();

      }
    }, [authState, oktaAuth]); // Update if authState changes

    if (!user) {
      return (
        <div>
          <p>Chargement...</p>
        </div>
      );
    }

    return (
        <div className={"page landing"}>
          {user &&
            <h2 className={"bleu text-center"}>
                Bonjour {user.firstName}
                <span className={"hand"}>
                    <img src={Hand} alt=""/>
                </span>
            </h2>
          }
            <div className={"boxes"} >
                <Link className={"box text-center"} to={"/traiter-grille"}>
                    <span className={"fa fa-grip-horizontal bg-icon"}></span>
                    <h3 className={"bleu"}>Traiter une grille</h3>
                </Link>
                <Link className={"box text-center"} to={"/expedier"}>
                    <span className={"fa fa-comment bg-icon"}></span>
                    <h3 className={"bleu"}>Expédier à un fabricant</h3>
                </Link>
            </div>

            <div className={"logo text-center"}>
                <img src={Logo} alt="GRDF"/>
            </div>
        </div>
    )
}

export {Landing};
