import React, {useEffect, useState} from 'react';
import {MainService} from "../_services";
import {useNavigate} from "react-router-dom";

function SelectProducts(props)  {
    const navigate = useNavigate();
    const ms = new MainService();
    const [products, setProducts] = React.useState([]);
    const [value, setValue] = useState('');
    const [totalWeight, setTotalWeight] = useState(0);
    const [totalLithiumWeight, setTotalLithiumWeight] = useState(0);
    const [transportNumber, setTransportNumber] = useState('');
    const [validationModal, setValidationModal] = useState(false);
    const [finishModal, setFinishModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const send = () => {
      setLoading(true)
      let obj = {
        articles : products.map(d => { return {productItemId : d.id}}),
        transportDocumentNumber : transportNumber
      }
      ms.sendItems(props.manufacturer.id, obj).then(
        (data)=> {
         setFinishModal(true)
        }
      )
    }

    const getItems = () => {
      ms.getItemsByManufacturer(props.manufacturer.id).then((data)=> {
        setProducts(data.data);
        getTotals(data.data)
      })
    }
    const goDashboard = () => navigate('/');
    useEffect(()=>{
      getItems();
    }, []);

    const getTotals = (data) => {
      let totalW = 0, totalLithuimW = 0;
      for (var i in data){
        totalLithuimW+= data[i].totalLithiumWeight;
        totalW += data[i].totalWeight;
      }
      setTotalLithiumWeight(totalLithuimW);
      setTotalWeight(totalW)
    }

    return (
        <>
            <p className={"grey"}>
                Récapitulatif du matériel à envoyer à <b className={"bleu"}>{props.manufacturer.name}</b>
            </p>
            <div className="search-bar">
                <span className="fa fa-magnifying-glass"></span>
            </div>
            <ul className={"list-items"}>
                {products
                    .filter(item => {
                        if (!value) return true
                        if (item.product.name.toLowerCase().includes(value.toLowerCase())) {
                            return true
                        }
                    })
                    .map((item,index) => (
                        <li>
                            <h4 className={"grey"}>{item.product.name}</h4>
                            <p>Quantité : {item.quantity} PC</p>
                            <p>Quantité de lithium: {item.totalLithiumWeight} g </p>
                            <p className={"text-right grey"}>
                                Poids : <i>{item.totalWeight / 1000}kg</i>
                            </p>
                        </li>
                    ))
                }

            </ul>
            <div className={"bottom-bar"}>
                <p className={"text-right "}>
                    TOTAL lithium : <b>{Number(totalLithiumWeight)/1000}kg</b>
                </p>
                <p className={"text-right "}>
                    TOTAL poids global : <b>{Number(totalWeight)/1000}kg</b>
                </p>
                <button type={'button'} className={'btn btn-full-blue'} disabled={products.length === 0}
                        onClick={() => {
                            setValidationModal(true)
                        }}
                        >Expedier
                </button>

            </div>
            {
                validationModal &&
                <div className={"modal-d"}>
                    <div className="wrapper-modal">
                        <div className={"modal-content small-modal b-btns no-p"}>
                            <div className="content-modal">
                                <h3>
                                    <b>Êtes-vous sûr de vouloir envoyer ce matériel à <span className={"bleu"}>
                                         {props.manufacturer.name}
                                        </span>

                                    </b>
                                </h3>
                                <p className={"mt_15"}>
                                    TOTAL lithium : <b>{Number(totalLithiumWeight)/1000}kg</b>
                                </p>
                                <p>
                                    TOTAL poids global : <b>{Number(totalWeight)/1000}kg</b>
                                </p>
                                <p className={"mt_15"}>Saisir le numéro du document de transport: </p>
                                <div className={"input-1"}>
                                    <span className={"fa fa-file icon-i"}></span>
                                    <input type="text"
                                           placeholder={"N° document de transport "}
                                           value={transportNumber}
                                           onChange={e => setTransportNumber(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="footer-modal">
                                <button className={"btn btn-white"} type={"button"} onClick={() => {
                                    setValidationModal(false)
                                }}>Annuler
                                </button>
                                <button className={"btn btn-blue"} type={"button"} onClick={send} disabled={!transportNumber || loading}>Envoyer</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
          {
            finishModal &&
            <div className={"modal-d"}>
              <div className="wrapper-modal">
                <div className={"modal-content small-modal"}>
                  <h3>
                    <b>Les articles sont bien envoyés à " <span className={"bleu"}>
                               {props.manufacturer && props.manufacturer.name}
                                </span> "

                    </b>
                  </h3>
                  <button className={"btn btn-full-blue"} type={"button"} onClick={goDashboard}>Terminer</button>
                </div>
              </div>
            </div>
          }
        </>
    )
}

export {SelectProducts};
