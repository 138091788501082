export const config = {
  oidc: {
    clientId: '0oadbivwlohfcQmSL0i7',
    issuer: 'https://grdf.okta-emea.com/oauth2/aus3k8giyen6PW0700i7',
    redirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email'],
    responseType: ['id_token', 'token','refresh_token'],
    pkce: true,
    nonce : "Rdg-auth0",
  }
};
